@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

* {
	box-sizing: border-box;
}
.progress {
	width: 100%;
	display: flex;
	justify-content: center;



}

.progress-container {
    display: flex;
	width: 56%;
	position: static;
	justify-content: space-between;
	height: 5px;
	align-items: center;
	margin: 2% 2% 2% 2%;
	border-radius: 10px;
	background-color:#D9E7EB;

}

.progressbar {
	opacity: 1;
	align-items: left;
	background: rgb(255, 22, 84);
}

.text {
	text-align: right;
	font-weight: bold;
}