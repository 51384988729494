* {
  margin: 0;
  padding: 0;

  @media (prefers-color-scheme: dark) {}

}

.data-list  {
  text-align: left;
  font-weight: 200;
  font-size: 1.1rem;
  font-family: 'CircularStd';
  margin-bottom:'2%';
  letter-spacing: 0.5px;
  line-height: 1.6;
  padding: 10px 10px 10px 10px;
  width:"50%";
  color:#4B5A5D
}

.data-list p {
  color:"blue"
}
.inputRounded .MuiOutlinedInput-root {
  border-radius: 10px;
}

.MuiSelect-outlined.MuiSelect-outlined {
  height: 15px
}

.MuiInputBase-input {
  width: 0%;
}

.MuiSelect-outlined.MuiSelect-outlined {
  text-align: left;
  font-size: 14px;
}

.ResetPassword_container {
  background: black;
  position: relative;
}

.ResetPassword_content {
  box-shadow: 0px 6px 16px rgba(19, 165, 121, 0.08);
}

.LoginPage_container {
  background-size: cover;
  height: "200vh";
  background: "blue";
}

.LoginPage_content {
  box-shadow: 0px 6px 16px rgba(19, 165, 121, 0.08);
}

.ResetSuccessfull_container {
  background-size: cover;
  height: "300vh";
}

.ResetSuccessfull_content {
  box-shadow: 0px 6px 16px rgba(19, 165, 121, 0.08);
}

.DownloadApp_container {
  background-size: cover;
  height: "200vh";
}

.ErrorPage_container {
  background-size: cover;
  height: "200vh";
}

.ErrorPage_content {
  box-shadow: 0px 6px 16px rgba(19, 165, 121, 0.08);
}

.dropdown {}

.MuiMenu-paper {
  padding-top: 10px;
}

.element.style {
  top: "100px"
}



.MuiPopover-paper {
  top: 100px;
}

.MuiPaper-root {
  margin-top: 9%;

}

.Mui-selected {}

.MuiSelect-selectMenu {
  background-color: white;
}

.MuiList-root {
  background-color: white;


}


.MuiListItem-root :hover {
  background-color: white;
}



.menu {
  background-color: white;

}


.inputRounded {}


.PrivateNotchedOutline-legendLabelled-7 {
  color: "black"
}

.css-12z75kq {
  height: 140px;

  /* width: 50%; */

}



.css-3okl2k {

  height: 55px;
  width: 70%;
  /* margin: 5% 2% 5% 2%; */

}

.css-3okl2k.Mui-expanded {}

.css-nkhozk {
  width: 21.%;
}

.css-91n69b {
  z-index: "1501 !important";
}


.css-1tx2y7g {
  position: relative;
  background-color: white;
  width: 22%;

}



@media screen and (max-width: 620px) {

  .css-1tx2y7g {
    position: relative;
    width: 53.8%;
  }

  .labelforId {
    margin-left: -10%;
   } 
   
}

@media screen and (min-width: 600px) and (max-width:930px) {

  .css-1tx2y7g {
    position: relative;
    background-color: white;
    width: 44.6%;
  }

  


}

@media screen and (min-width: 700px) and (max-width:930px) {

  .css-1tx2y7g {
    position: relative;
    background-color: white;
    width: 44.2%;
  }
}




@media screen and (min-width: 950px) and (max-width:1100px) {

  .css-1tx2y7g {
    position: relative;
    background-color: white;
    width: 33.2%;
  }

}


@media screen and (max-width:560px) {

  .css-1tx2y7g {
    position: relative;
    background-color: white;
    width: 54%
  }


  * {}
}


@media (prefers-color-scheme: dark) {
  .day.dark-scheme {
    background: #333;
    color: white;
  }

  .night.dark-scheme {
    background: black;
    color: #ddd;
  }
}

@media (prefers-color-scheme: light) {

  .MuiCardContent-root {
    background: "blue"
  }
}

.MuiInputBase-input::-ms-clear {
  display: none !important;
}
